import "./LabeledIconButton.scss";
import { IconButton, SvgIconTypeMap, Typography, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export default function LabeledIconButton({
    Icon,
    label,
    size,
    fontSize,
    onClick,
    selected
}:{
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    label: string,
    size?: number,
    fontSize?: number,
    onClick?: () => void,
    selected?: boolean
}) {
    const theme = useTheme();

    return (
        <div className={`LabeledIconButton${selected ? " selected" : ""}`} style={{ borderColor: theme.palette.divider }} onClick={onClick}>
            <IconButton sx={{ width: size, height: size, marginBottom: "8px" }}>
                <Icon sx={{ width: size, height: size }} />
            </IconButton>
            <Typography fontSize={fontSize} align={"center"}>{label}</Typography>
        </div>
    );
}