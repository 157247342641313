import { GridColDef } from "@mui/x-data-grid";
import StringUtils from "./StringUtils";
import { SchemaDefinition } from "./schemas";

const SchemaUtils = {
    async getSchema(tableName: string): Promise<SchemaDefinition> {
        return new Promise(async (resolve, reject) => {
            try {
                const schemas = (await import("./schemas"));
                let schemaImportName = `${tableName}Schema` as keyof typeof schemas;
                resolve(schemas[schemaImportName]);
            } catch (_) {
                reject([{ error: "The specified table name was not found." }]);
            }
        });
    },

    async getTableApiName(tableName: string): Promise<string> {
        return new Promise(async (resolve) => {
            const schema = await SchemaUtils.getSchema(tableName);
            resolve(StringUtils.convertToFirstCharLowercase(schema.plural));
        });
    },

    colDefHasField(colDef: GridColDef[], fieldName: string): boolean {
        return colDef.some(def => def.field === fieldName);
    }
}

export default SchemaUtils;
