import './App.scss';
import { createContext, useMemo, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { AlertColor, CssBaseline, Stack, createTheme } from '@mui/material';
import HeaderMain from '../components/header/HeaderMain/HeaderMain';
import SidebarMain from '../components/sidebar/SidebarMain/SidebarMain';
import { Outlet } from 'react-router-dom';
import { cloudEnv } from '../cloud-env';
import { AuthProvider } from 'oidc-react';
import LoadingOverlay from '../components/auth/LoadingOverlay/LoadingOverlay';
import { SnackbarState } from '../utils/types';
import SnackbarUtils from '../utils/SnackbarUtils';
import SnackNotification from '../components/common/SnackNotification/SnackNotification';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const SnackNotificationContext = createContext({ displaySnackbarMessage: (severity: AlertColor, message: string) => {} });

const oidcConfig = {
    clientId: cloudEnv.clientId,
    redirectUri: `${document.location.origin}/`,
    authority: cloudEnv.openidconnectUrl.replace("/.well-known/openid-configuration", ""),
    autoSignIn: true
};

function App() {
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(SnackbarUtils.getDefaultState());
    const snackbarContext = useMemo(() => ({
        displaySnackbarMessage: (severity: AlertColor, message: string) => {
            switch (severity) {
                case "error": {
                    SnackbarUtils.openError(setSnackbarState, message);
                    break;
                }
                case "info": {
                    SnackbarUtils.openInfo(setSnackbarState, message);
                    break;
                }
                case "success": {
                    SnackbarUtils.openSuccess(setSnackbarState, message);
                    break;
                }
                case "warning": {
                    SnackbarUtils.openWarning(setSnackbarState, message);
                    break;
                }
            }
        }
    }), []);

    return (
        <div>
            <SnackNotificationContext.Provider value={snackbarContext}>
                <AuthProvider {...oidcConfig}>
                    <LoadingOverlay />
                    <HeaderMain />
                    <Stack direction={"row"}>
                        <SidebarMain />
                        <Outlet />
                    </Stack>
                </AuthProvider>
                <SnackNotification state={snackbarState} setStateFunction={setSnackbarState} />
            </SnackNotificationContext.Provider>
        </div>
    );
}

export default function ToggleColorMode() {
    const previousMode = localStorage.getItem("mode");
    const [mode, setMode] = useState<'light' | 'dark'>(previousMode as ('light' | 'dark') || 'light');
    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        }
    }), []);
  
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode
                }
            }),
        [mode],
    );

    localStorage.setItem("mode", mode);
  
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
