import { Backdrop, CircularProgress } from "@mui/material";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";

export default function LoadingOverlay() {
    const [ loading, setLoading ] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        setLoading(auth.userData === null || auth.userData === undefined);
    }, [auth]);

    return (
        <>
            { loading &&
                <Backdrop open={loading}>
                    <CircularProgress />
                </Backdrop>
            }
        </>
    );
}