
import React from "react";
import ModalBase from "../ModalBase/ModalBase";

export default function TextDisplayModal({
    children,
    open,
    parentOpenFunction,
    parentCloseFunction,
    title,
    text
}: {
    children?: React.ReactNode,
    open: boolean,
    parentOpenFunction: () => void,
    parentCloseFunction: () => void,
    title: string,
    text: string
}) {
    return (
        <>
            <ModalBase title={title} open={open} parentCloseFunction={parentCloseFunction}>
                <div>
                    <pre>
                        {text}
                    </pre>
                </div>
                <div>
                    {children}
                </div>
            </ModalBase>
        </>
    );
}
