import "./Resoconto.scss";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import ResocontoContent from "./ResocontoContent";
import { useAuth } from "oidc-react";
import { useLocation } from "react-router-dom";
import UserDataUtils from "../../utils/UserDataUtils";
import ApiProvider from "../../utils/ApiProvider";

export default function Resoconto() {
    const auth = useAuth();
    const location = useLocation();

    const api = ApiProvider.getApi();

    let externalUserId: string | undefined;
    let externalUserName: string | undefined;
    if (location.state) {
        if (location.state.userId) {
            externalUserId = location.state.userId;
        }

        if (location.state.name) {
            externalUserName = location.state.name;
        }
    }

    function isManagerViewing() {
        return externalUserId !== undefined;
    }

    return (
        <div className="content">
            <PageLabelBar title={`Resoconto Personale${isManagerViewing() ? ` di ${externalUserName}` : ""}`} />
            { isManagerViewing() && auth.userData &&
                <ResocontoContent userId={externalUserId as string} isExternalUserId api={api} />
            }
            { !isManagerViewing() && auth.userData &&
                <ResocontoContent userId={UserDataUtils.getUserId(auth.userData)} api={api} />
            }
        </div>
    );
}