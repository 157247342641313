import "./ApprovalModal.scss";
import { AuthContextProps } from "oidc-react";
import ApiAdapter from "../../api/ApiAdapter";
import { createRef, useContext, useState } from "react";
import ModalBase from "../common/ModalBase/ModalBase";
import { Button, TextField } from "@mui/material";
import { SnackNotificationContext } from "../../app/App";

export type ApprovalModalState = {
    name: "under_approval" | "approve" | "reject",
    title: string
}

export const UnderApprovalState: ApprovalModalState = {
    name: "under_approval",
    title: "Mandare la Proposta in approvazione?"
}

export const ApproveState: ApprovalModalState = {
    name: "approve",
    title: "Approvare la Proposta?"
}

export const RejectState: ApprovalModalState = {
    name: "reject",
    title: "Rifiutare la Proposta?"
}

export default function ApprovalModal({
    open,
    parentOpenFunction,
    parentCloseFunction,
    auth,
    api,
    row,
    state,
    onSuccessFunction
}: {
    open: boolean,
    parentOpenFunction: () => void,
    parentCloseFunction: () => void,
    auth: AuthContextProps,
    api: ApiAdapter,
    row: any,
    state: ApprovalModalState,
    onSuccessFunction: () => void
}) {
    const [ processingRequest, setProcessingRequest ] = useState(false);
    const snackNotificationContext = useContext(SnackNotificationContext);
    const rejectMotivationFormRef = createRef<HTMLFormElement>();

    async function actionConfirmed() {
        if (!auth.userData) {
            return;
        }

        setProcessingRequest(true);
        switch (state.name) {
            case "under_approval": {
                let result = await api.requestProposalApproval(row.id, auth.userData.access_token);
                if (result) {
                    snackNotificationContext.displaySnackbarMessage("success", "Proposta mandata in approvazione!");
                    onSuccessFunction();
                } else {
                    snackNotificationContext.displaySnackbarMessage("error", "Impossibile completare l'operazione.");
                }

                break;
            }
            case "approve": {
                let result = await api.approveProposal(row.id, auth.userData.access_token);
                if (result) {
                    snackNotificationContext.displaySnackbarMessage("success", "Proposta approvata!");
                    onSuccessFunction();
                } else {
                    snackNotificationContext.displaySnackbarMessage("error", "Impossibile completare l'operazione.");
                }

                break;
            }
            case "reject": {
                let result = false;
                if (rejectMotivationFormRef.current) {
                    let formData = new FormData(rejectMotivationFormRef.current);
                    result = await api.rejectProposal(row.id, auth.userData.access_token, formData.get("manager_note")?.toString() ?? "");
                }

                if (result) {
                    snackNotificationContext.displaySnackbarMessage("success", "Proposta rifiutata!");
                    onSuccessFunction();
                } else {
                    snackNotificationContext.displaySnackbarMessage("error", "Impossibile completare l'operazione.");
                }

                break;
            }
        }

        setProcessingRequest(false);
        parentCloseFunction();
    }

    return (
        <>
            <ModalBase title={state.title} open={open} parentCloseFunction={parentCloseFunction}>
                <div className="approvalModal">
                    <p>
                        Una volta data la conferma, non sarà più possibile annullare l'azione.
                    </p>
                    { state.name === RejectState.name &&
                        <form ref={rejectMotivationFormRef}>
                            <TextField name="manager_note" label="Motivazioni" multiline rows={3} sx={{ width: "100%" }} />
                        </form>
                    }
                    <div className="actions">
                        <Button variant="outlined" disabled={processingRequest} onClick={() => actionConfirmed()}>Conferma</Button>
                        <Button color="error" variant="outlined" disabled={processingRequest} onClick={() => parentCloseFunction()}>Annulla</Button>
                    </div>
                </div>
            </ModalBase>
        </>
    )
}