const StringUtils = {
    convertToFirstCharLowercase(string: string): string {
        return string.substring(0, 1).toLowerCase() + string.substring(1);
    },

    toFirstCharUppercase(string: string): string {
        return string.substring(0, 1).toUpperCase() + string.substring(1);
    },

    getFormattedAddress(street: string, house_number: string, city: string, postal_code: string): string {
        if (!street || !house_number || !city || !postal_code) {
            return "";
        }
    
        return `${street} ${house_number}, ${city}, ${postal_code}`;
    }
}

export default StringUtils;
