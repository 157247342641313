import { Button, Typography } from "@mui/material";
import { useAuth } from "oidc-react";
import UserDataUtils from "../../../utils/UserDataUtils";
import { cloudEnv } from "../../../cloud-env";
import Axios from "axios";

export default function AuthButton() {
    const auth = useAuth();

    if (auth.isLoading) {
        return <></>
    }

    async function signOut() {
        try {
            const logoutEndpoint = (await Axios.get(cloudEnv.openidconnectUrl)).data.end_session_endpoint;
            const logoutUri = `${window.location.origin}/`;
            const clientId = cloudEnv.clientId;
            sessionStorage.clear();
            window.location.href = `${logoutEndpoint}?client_id=${encodeURIComponent(clientId)}&logout_uri=${encodeURIComponent(logoutUri)}`;
        } catch(e) {
            console.error(e);
            return;
        }
    }

    return (
        <>
            <Typography sx={{alignSelf: "center"}}>{auth.userData && UserDataUtils.getName(auth.userData)}</Typography>
            <Button onClick={() => signOut()}>Sign Out</Button>
        </>
    )
}