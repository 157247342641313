import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import Certificazioni from './app/certificazioni/Certificazioni';
import Corsi from './app/corsi/Corsi';
import Database from './app/database/Database';
import Risorse from './app/risorse/Risorse';
import Skills from './app/skills/Skills';
import Team from './app/team/Team';
import Utenti from './app/utenti/Utenti';
import Dashboard from './app/dashboard/Dashboard';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/auth/PrivateRoute/PrivateRoute';
import Matrix from './app/matrix/Matrix';
import Proposte from './app/proposte/Proposte';
import Resoconto from './app/resoconto/Resoconto';
import Tutorial from './app/tutorial/Tutorial';

interface Route {
    path: string,
    element: JSX.Element,
    children?: Array<Route>
}

function addLayoutToRoutes(routes: Array<Route>, layout: JSX.Element): Array<Route> {
    return routes.map(route => {
        return {
            path: route.path,
            element: layout,
            children: [
                route
            ]
        }
    });
}

const routes = [
    {
        path: "/",
        element: <Dashboard />
    },
    {
        path: "/tutorial",
        element: <Tutorial />
    },
    {
        path: "/corsi",
        element: <PrivateRoute requiredRole="team-member" />,
        children: [
            {
                path: "",
                element: <Corsi />
            }
        ]
    },
    {
        path: "/proposte",
        element: <PrivateRoute requiredRole="team-member" />,
        children: [
            {
                path: "",
                element: <Proposte />
            }
        ]
    },
    {
        path: "/certificazioni",
        element: <PrivateRoute requiredRole="team-member" />,
        children: [
            {
                path: "",
                element: <Certificazioni />
            }
        ]
    },
    {
        path: "/skills",
        element: <PrivateRoute requiredRole="team-member" />,
        children: [
            {
                path: "",
                element: <Skills />
            }
        ]
    },
    {
        path: "/resoconto",
        element: <PrivateRoute requiredRole="team-member" />,
        children: [
            {
                path: "",
                element: <Resoconto />
            }
        ]
    },
    {
        path: "/matrix",
        element: <PrivateRoute requiredRole="skills-user" />,
        children: [
            {
                path: "",
                element: <Matrix />
            }
        ]
    },
    {
        path: "/team",
        element: <PrivateRoute requiredRole="manager" />,
        children: [
            {
                path: "",
                element: <Team />
            }
        ]
    },
    {
        path: "/utenti",
        element: <PrivateRoute requiredRole="hr" />,
        children: [
            {
                path: "",
                element: <Utenti />
            }
        ]
    },
    {
        path: "/risorse",
        element: <PrivateRoute requiredRole="data-manager" />,
        children: [
            {
                path: "",
                element: <Risorse />
            }
        ]
    },
    {
        path: "/database",
        element: <PrivateRoute requiredRole="admin" />,
        children: [
            {
                path: "",
                element: <Database />
            }
        ]
    }
];

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(addLayoutToRoutes(routes, <App />));

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
