import "./DeleteItemModal.scss";
import { Button } from "@mui/material";
import ApiAdapter from "../../../api/ApiAdapter";
import { AuthContextProps } from "oidc-react";
import { useContext, useState } from "react";
import ModalBase from "../ModalBase/ModalBase";
import { SnackNotificationContext } from "../../../app/App";

export default function DeleteItemModal({
    open,
    parentOpenFunction,
    parentCloseFunction,
    auth,
    api,
    table,
    row,
    onSuccessFunction
}: {
    open: boolean,
    parentOpenFunction: () => void,
    parentCloseFunction: () => void,
    auth: AuthContextProps,
    api: ApiAdapter,
    table: string,
    row: any,
    onSuccessFunction: () => void
}) {
    const [ processingRequest, setProcessingRequest ] = useState(false);
    const snackNotificationContext = useContext(SnackNotificationContext);

    async function deleteConfirmed() {
        if (!auth.userData) {
            return;
        }

        let rowId = row.id;

        if (!rowId) {
            return;
        }

        setProcessingRequest(true);
        let result = await api.deleteDataRow(table, auth.userData.access_token, rowId);
        
        if (result) {
            snackNotificationContext.displaySnackbarMessage("success", "Elemento eliminato!");
            onSuccessFunction();
        } else {
            snackNotificationContext.displaySnackbarMessage("error", "Impossibile completare l'operazione.");
        }

        setProcessingRequest(false);
        parentCloseFunction();
    }

    return (
        <>
            <ModalBase title={"Eliminare definitivamente l'elemento?"} open={open} parentCloseFunction={parentCloseFunction}>
                <div className="deleteItemModal">
                    <p>
                        Una volta eliminato, l'elemento non potrà più essere recuperato. 
                    </p>
                    <div className="actions">
                        <Button variant="outlined" disabled={processingRequest} onClick={() => deleteConfirmed()}>Conferma</Button>
                        <Button color="error" variant="outlined" disabled={processingRequest} onClick={() => parentCloseFunction()}>Annulla</Button>
                    </div>
                </div>
            </ModalBase>
        </>
    );
}
