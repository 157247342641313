import { Add, Approval } from "@mui/icons-material";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";

export default function ProposteUseCases() {
    return (
        <>
            <Divider />
            <Stack direction={"column"} gap={"12px"} sx={{ marginTop: "12px" }}>
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Aggiungere una nuova Proposta formativa</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Fare click sul bottone <Button variant="contained" startIcon={<Add />}>Aggiungi</Button> collocato sopra alla tabella.</Typography>
                        <Typography fontSize={"18px"}>&bull; Aprire il dropdown del campo <b>Corso</b>, ed iniziare a scrivere il nome del corso voluto.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Se il corso compare nella lista, sceglierlo e procedere a riempire il resto dei campi.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Altrimenti, il corso non &egrave; presente nel database. Selezionare "Nuovo Corso", inserire i dati del corso voluto, e riempire il resto dei campi.</Typography>
                        <Typography fontSize={"18px"}>&bull; La proposta appena creata può ancora essere modificata, e non potrà essere approvata finché non verrà mandata in approvazione.</Typography>
                    </Stack>
                </div>
                <Divider />
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Mandare una Proposta in approvazione</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Individuare la proposta che si vuole mandare in approvazione.</Typography>
                        <Typography fontSize={"18px"}>&bull; Fare click sull'icona del timbro <IconButton><Approval /></IconButton> in fondo alla riga corrispondente.</Typography>
                        <Typography fontSize={"18px"}>&bull; Una volta data la conferma, la proposta non sarà più modificabile, e potrà essere approvata o rifiutata.</Typography>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}