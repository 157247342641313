import { Button, Stack, Typography } from "@mui/material";
import DataTable from "../../components/common/DataTable/DataTable";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import { Add, Cancel, Description, Edit, Flag, List, People, PlayArrow } from "@mui/icons-material";
import { useAuth } from "oidc-react";
import UserDataUtils from "../../utils/UserDataUtils";
import { Link, useLocation } from "react-router-dom";
import ApiProvider from "../../utils/ApiProvider";
import { CourseAttendedSchema } from "../../utils/schemas";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import CourseAttendedStateChangeModal from "../../components/corsi/CourseAttendedStateChangeModal/CourseAttendedStateChangeModal";
import { useState } from "react";
import TextDisplayModal from "../../components/common/TextDisplayModal/TextDisplayModal";

export default function Corsi() {
    const [ dataTableKey, setDataTableKey ] = useState(new Date().toString());
    const [ changeStateModalOpen, setChangeStateModalOpen ] = useState(false);
    const [ changeStateModalState, setChangeStateModalState ] = useState<{ newState: "planned" | "started" | "completed" | "discontinued", row: any }>({
        newState: "started",
        row: {}
    });
    const [ textModalOpen, setTextModalOpen ] = useState(false);
    const auth = useAuth();
    const location = useLocation();
    
    let externalUserId: string | undefined;
    let externalUserName: string | undefined;
    if (location.state) {
        if (location.state.userId) {
            externalUserId = location.state.userId;
        }

        if (location.state.name) {
            externalUserName = location.state.name;
        }
    }

    function reloadDataTableKey(newState: "planned" | "started" | "completed" | "discontinued") {
        setDataTableKey(new Date().toString());
        if (newState === "completed") {

        }
    }

    function openModal(newState: "planned" | "started" | "completed" | "discontinued", row: any) {
        setChangeStateModalState({ newState, row });
        setChangeStateModalOpen(true);
    }

    const getStateActions = (params: GridRowParams<any>) => {
        return [
            <GridActionsCellItem icon={<Edit />} label="Modifica Data Inizio" title="Modifica Data Inizio"
                onClick={() => openModal("planned", params.row)} disabled={!(params.row.state === "planned")} />,
            <GridActionsCellItem icon={<PlayArrow />} label="Inizia" title="Inizia"
                onClick={() => openModal("started", params.row)} disabled={!(params.row.state === "planned")} />,
            <GridActionsCellItem icon={<Flag />} label="Completa" title="Completa"
                onClick={() => openModal("completed", params.row)} disabled={!(params.row.state === "started")} />,
            <GridActionsCellItem icon={<Cancel />} label="Abbandona" title="Abbandona"
                onClick={() => openModal("discontinued", params.row)} disabled={!(params.row.state === "planned" || params.row.state === "started")} />
        ];
    }

    // If an External User ID is present, a manager is viewing their member's proposals.
    function isManagerViewing() {
        return externalUserId !== undefined;
    }

    return (
        <>
            <div className="content">
                <PageLabelBar title={`I ${externalUserName ? `corsi di ${externalUserName}` : "tuoi Corsi"}`} />
                <div className="fullWidth">
                    { isManagerViewing() && auth.userData &&
                        <DataTable key={`${dataTableKey}-external`} api={ApiProvider.getApi()} table={CourseAttendedSchema.name} exportDataButton
                            userId={externalUserId}></DataTable>
                    }
                    { !isManagerViewing() && auth.userData &&
                        <DataTable key={`${dataTableKey}-internal`} api={ApiProvider.getApi()} table={CourseAttendedSchema.name} addItemButton exportDataButton
                            additionalButtons={!externalUserId && <Link to={"/proposte"}><Button variant="contained" startIcon={<Add />}>Proponi Nuovo</Button></Link>}
                            userId={UserDataUtils.getUserId(auth.userData)} getActions={getStateActions}></DataTable>
                    }
                </div>
                { externalUserId &&
                    <div className="belowDataTableButtonBar fullWidth">
                        <Stack gap={"8px"} width={"fit-content"}>
                            <Link to={"/team"} state={{}}><Button variant="contained" startIcon={<People />} sx={{ justifyContent: "left" }} fullWidth><Typography>Torna al tuo Team</Typography></Button></Link>
                            <Link to={"/corsi"} state={{}}><Button variant="contained" startIcon={<List />} sx={{ justifyContent: "left" }} fullWidth><Typography>Vai ai tuoi Corsi</Typography></Button></Link>
                        </Stack>
                    </div>
                }
            </div>
            <CourseAttendedStateChangeModal api={ApiProvider.getApi()} auth={auth} newState={changeStateModalState.newState}
                open={changeStateModalOpen} row={changeStateModalState.row} onSuccessFunction={reloadDataTableKey}
                parentOpenFunction={() => setChangeStateModalOpen(true)} parentCloseFunction={() => setChangeStateModalOpen(false)} />
            <TextDisplayModal open={textModalOpen} parentCloseFunction={() => setTextModalOpen(false)} parentOpenFunction={() => setTextModalOpen(true)}
                title="Step Successivo" text={`Adesso che hai completato il corso, il prossimo step\nè quello di registrare la tua certificazione.`}>
                
                <Link to={"/certificazioni"}><Button variant="outlined" startIcon={<Description />}>Vai a Certificazioni</Button></Link>
            </TextDisplayModal>
        </>
    );
}
