import "./SidebarMain.scss";
import { Checkbox, Divider, IconProps, Paper, Stack, SvgIconTypeMap } from "@mui/material";
import { AccessTime, Badge, Description, Extension, Home, Lightbulb, List, Lock, LockOpen, People, ReceiptLong, Storage, WorkspacePremium } from "@mui/icons-material";
import { ComponentType, useLayoutEffect, useState } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import NavigationLink from "../NavigationLink/NavigationLink";
import { useAuth } from "oidc-react";
import UserDataUtils from "../../../utils/UserDataUtils";

function getComponentFromIcon(icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>): ComponentType<IconProps> {
    return icon as ComponentType<IconProps>;
}

const LOCAL_STORAGE_LOCKED_KEY = "lockedSidebar";

export default function SidebarMain() {
    const [ sidebarLocked, setSidebarLocked ] = useState<boolean>(false);
    const auth = useAuth();

    // Check if user's preference on locked is set in local storage.
    useLayoutEffect(() => {
        let wantsLocked = localStorage.getItem(LOCAL_STORAGE_LOCKED_KEY);
        if (wantsLocked && wantsLocked === "true") {
            setSidebarLocked(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onLockChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setSidebarLocked(checked);
        localStorage.setItem(LOCAL_STORAGE_LOCKED_KEY, String(checked));
    }

    return (
        <div className="SidebarMain">
            <Paper className={`bar ${sidebarLocked && "locked"}`}>
                <Stack direction={"column"} className="navigation">
                    <Checkbox onChange={onLockChange} icon={<LockOpen />} checkedIcon={<Lock />} checked={sidebarLocked} sx={{ width: "40px" }}></Checkbox>
                    <Divider sx={{ width: "40px" }} />
                    { auth.userData &&
                        <>
                            <NavigationLink path="/" text="Dashboard" Icon={getComponentFromIcon(Home)} />
                            { UserDataUtils.userHasRole(auth.userData, "team-member") &&
                                <>
                                    <NavigationLink path="/corsi" text="Corsi" Icon={getComponentFromIcon(List)} />
                                    <NavigationLink path="/proposte" text="Proposte" Icon={getComponentFromIcon(AccessTime)} />
                                    <NavigationLink path="/certificazioni" text="Certificazioni" Icon={getComponentFromIcon(Description)} />
                                    <NavigationLink path="/skills" text="Skills" Icon={getComponentFromIcon(Lightbulb)} />
                                    <NavigationLink path="/resoconto" text="Resoconto" Icon={getComponentFromIcon(ReceiptLong)} />
                                </>
                            }
                            { UserDataUtils.userHasRole(auth.userData, "skills-user") &&
                                <NavigationLink path="/matrix" text="Matrix" Icon={getComponentFromIcon(Extension)} />
                            }
                            { UserDataUtils.userHasRole(auth.userData, "manager") &&
                                <NavigationLink path="/team" text="Team" Icon={getComponentFromIcon(People)} />
                            }
                            { UserDataUtils.userHasRole(auth.userData, "hr") &&
                                <NavigationLink path="/utenti" text="Utenti" Icon={getComponentFromIcon(Badge)} />
                            }
                            { UserDataUtils.userHasRole(auth.userData, "data-manager") &&
                                <NavigationLink path="/risorse" text="Risorse" Icon={getComponentFromIcon(WorkspacePremium)} />
                            }
                            { UserDataUtils.userHasRole(auth.userData, "admin") &&
                                <NavigationLink path="/database" text="Database" Icon={getComponentFromIcon(Storage)} />
                            }
                        </>
                    }
                </Stack>
            </Paper>
        </div>
    );
}
