import "./Notifications.scss";
import { Close, NotificationsRounded } from "@mui/icons-material";
import { Backdrop, CircularProgress, Divider, Drawer, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import ColorModeToggle from "../ColorModeToggle/ColorModeToggle";
import { useAuth } from "oidc-react";
import ApiProvider from "../../../utils/ApiProvider";
import UserDataUtils from "../../../utils/UserDataUtils";
import { NotificationObject } from "../../../utils/types";

function isNavigationKey(event: React.KeyboardEvent): boolean {
    return event.key === "Tab" || event.key === "Shift"
}

export default function Notifications() {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ notifications, setNotifications ] = useState<NotificationObject[]>([]);
    const auth = useAuth();

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event.type === "keydown" && isNavigationKey(event as React.KeyboardEvent)) {
                return;
            }

            setIsOpen(open);
            reloadNotifications();
        }

    async function reloadNotifications() {
        if (!auth.userData) {
            return;
        }

        setLoading(true);
        const data = await ApiProvider.getApi().getNotifications(UserDataUtils.getUserId(auth.userData), auth.userData.access_token) as NotificationObject[];
        setNotifications(data);
        setLoading(false);
    }

    const dateFormatter = new Intl.DateTimeFormat("it-IT", { dateStyle: "medium", timeStyle: "short" });

    return (
        <>
            <IconButton onClick={toggleDrawer(true)}>
                <NotificationsRounded />
            </IconButton>
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)} className="Notifications">
                <Paper className="hiddenPanel">
                    <Paper className="header">
                        <IconButton onClick={toggleDrawer(false)}><Close /></IconButton>
                        <Typography className="title" fontSize={"24px"} textAlign={"center"} display={"flex"}>Notifiche</Typography>
                        <ColorModeToggle />
                    </Paper>
                    <Paper className="notificationsPanel" sx={{ overflow: "scroll" }}>
                        <Backdrop open={loading} sx={{ position: "absolute", color: (theme) => theme.palette.background.default, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                            <CircularProgress sx={{ margin: "auto" }} />
                        </Backdrop>
                        <div className="notificationContent">
                            {
                                notifications.map(notification => 
                                    <div key={`${notification.notify_date}-container`} className="notificationFullWidth">
                                        <div className="notificationContainer" key={notification.notify_date}>
                                            <Typography key={`${notification.notify_date}-content`}>{notification.message}</Typography>
                                            <Typography key={`${notification.notify_date}-timestamp`} fontSize={"12px"}
                                                textAlign={"right"} sx={{ marginTop: "2px" }}>
                                                    {dateFormatter.format(new Date(notification.notify_date))}
                                            </Typography>
                                        </div>
                                        <Divider key={`${notification.notify_date}-divider`} sx={{ marginTop: "8px", marginBottom: "16px" }}/>
                                    </div>
                                )
                            }
                        </div>
                    </Paper>
                </Paper>
            </Drawer>
        </>
    );
}