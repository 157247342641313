const LocationUtils = {
    isDatabasePage(pathname: string) {
        return pathname.startsWith("/database");
    },

    isResourcesPage(pathname: string) {
        return pathname.startsWith("/risorse");
    }
}

export default LocationUtils;