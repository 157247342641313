import "./NavigationLink.scss";
import { IconProps, Stack, Typography, useTheme } from "@mui/material";
import { ComponentType } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavigationLink({
    path,
    text,
    Icon
}: {
    path: string,
    text: string,
    Icon: ComponentType<IconProps>
}) {
    const theme = useTheme();
    const location = useLocation();

    let backgroundColor;
    if (theme.palette.mode === 'dark') {
        backgroundColor = "rgba(255, 255, 255, 0.1)";
    } else {
        backgroundColor = "rgba(0, 0, 0, 0.1)";
    }

    let pathName = location.pathname;

    return (
        <div className={`NavigationLink ${theme.palette.mode}`}>
            <Link to={path}>
                <Stack direction={"row"} sx={{ display: "flex", alignItems: "center" }}>
                    <Icon sx={{ width: "40px", height: "40px", borderRadius: "10px", backgroundColor: pathName === path ? backgroundColor : "" }} />
                    <Typography noWrap fontSize={"20px"} fontWeight={"600"} paddingInline={"8px"} className="noselect">{ text }</Typography>
                </Stack>
            </Link>
        </div>
    );
}