import { useAuth } from "oidc-react";
import { Navigate, Outlet } from "react-router-dom";
import UserDataUtils from "../../../utils/UserDataUtils";
import { useEffect, useState } from "react";


export default function PrivateRoute({
    requiredRole
}: {
    requiredRole: string
}) {
    const user = useAuth();
    const [ currentReturn, setCurrentReturn ] = useState(<></>);

    useEffect(() => {
        if (user.isLoading) {
            return;
        }

        if (user.userData && UserDataUtils.userHasRole(user.userData, requiredRole)) {
            setCurrentReturn(<Outlet />);
        } else {
            setCurrentReturn(<Navigate to="/" replace />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.isLoading, user.userData])
    
    return (
        <>
            { currentReturn }
        </>
    )
}
