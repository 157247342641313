import { Edit } from "@mui/icons-material";
import { Divider, IconButton, Stack, Typography } from "@mui/material";

export default function UtentiUseCases() {
    return (
        <>
            <Divider />
            <Stack direction={"column"} gap={"12px"} sx={{ marginTop: "12px" }}>
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Assegnare un Manager ad un Utente</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Fare click sull'icona <IconButton><Edit /></IconButton> in fondo alla riga corrispondente all'Utente a cui si vuole assegnare il Manager.</Typography>
                        <Typography fontSize={"18px"}>&bull; Nel campo che appare, cercare il Manager per nome o cognome. Una volta trovato, selezionarlo, e confermare.</Typography>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}