import "./HeaderMain.scss";
import ColorModeToggle from "../ColorModeToggle/ColorModeToggle";
import { Box, Paper } from "@mui/material";
import Logo from "../Logo/Logo";
import Notifications from "../Notifications/Notifications";
import AuthButton from "../../auth/AuthButton/AuthButton";
import InfoButton from "../InfoButton/InfoButton";

export default function HeaderMain() {
    return (
        <div className="HeaderMain">
            <Paper className="bar">
                <Box className="left">
                    <Logo width={64} height={64} />
                </Box>
                <Box className="right">
                    <AuthButton />
                    <Notifications />
                    <ColorModeToggle />
                    <InfoButton />
                </Box>
            </Paper>
        </div>
    );
}