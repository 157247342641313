import { SnackbarState } from "./types";

const SnackbarUtils = {
    openSuccess: (setStateFunction: React.Dispatch<React.SetStateAction<SnackbarState>>, message: string) => {
        setStateFunction({
            open: true,
            severity: "success",
            message
        });
    },

    openError: (setStateFunction: React.Dispatch<React.SetStateAction<SnackbarState>>, message: string) => {
        setStateFunction({
            open: true,
            severity: "error",
            message
        });
    },

    openInfo: (setStateFunction: React.Dispatch<React.SetStateAction<SnackbarState>>, message: string) => {
        setStateFunction({
            open: true,
            severity: "info",
            message
        });
    },

    openWarning: (setStateFunction: React.Dispatch<React.SetStateAction<SnackbarState>>, message: string) => {
        setStateFunction({
            open: true,
            severity: "warning",
            message
        });
    },

    close: (setStateFunction: React.Dispatch<React.SetStateAction<SnackbarState>>, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setStateFunction(oldState => {
            return { ...oldState, open: false }
        });
    },

    getDefaultState: (): SnackbarState => {
        return {
            open: false,
            severity: "success",
            message: "Success!"
        }
    }
};

export default SnackbarUtils;