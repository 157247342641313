import { Add } from "@mui/icons-material";
import { Button, Divider, Stack, Typography } from "@mui/material";

export default function CertificazioniUseCases() {
    return (
        <>
            <Divider />
            <Stack direction={"column"} gap={"12px"} sx={{ marginTop: "12px" }}>
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Registrare una Certificazione acquisita</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Fare click sul bottone <Button variant="contained" startIcon={<Add />}>Aggiungi</Button> collocato sopra alla tabella.</Typography>
                        <Typography fontSize={"18px"}>&bull; Aprire il dropdown del campo <b>Certificazione</b>, ed iniziare a scrivere il nome della certificazione voluta.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Se la certificazione compare nella lista, sceglierla e procedere a riempire il resto dei campi.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Altrimenti, la certificazione non &egrave; presente nel database e pu&ograve; eventualmente essere aggiunta da un Data Manager.</Typography>
                        <Typography fontSize={"18px"}>&bull; Nota: il campo <b>URL</b> va riempito con un link al documento che attesta l'ottenimento della certificazione.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Se non hai a disposizione tale link, puoi caricare il documento su Drive ed inserire un link a quello.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Fai attenzione che il link ad un documento sul Drive sia accessibile dagli altri.</Typography>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}