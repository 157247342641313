import "./CourseAttendedStateChangeModal.scss";
import { Button } from "@mui/material";
import ModalBase from "../../common/ModalBase/ModalBase";
import { AuthContextProps } from "oidc-react";
import ApiAdapter from "../../../api/ApiAdapter";
import { FormEvent, createRef, useContext, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { SnackNotificationContext } from "../../../app/App";

export default function CourseAttendedStateChangeModal({
    open,
    parentOpenFunction,
    parentCloseFunction,
    auth,
    api,
    row,
    onSuccessFunction,
    newState
}: {
    open: boolean,
    parentOpenFunction: () => void,
    parentCloseFunction: () => void,
    auth: AuthContextProps,
    api: ApiAdapter,
    row: any,
    onSuccessFunction: (newState: "planned" | "started" | "completed" | "discontinued") => void,
    newState: "planned" | "started" | "completed" | "discontinued"
}) {
    const [ processingRequest, setProcessingRequest ] = useState(false);
    const snackNotificationContext = useContext(SnackNotificationContext);
    const formRef = createRef<HTMLFormElement>();

    function getTitle() {
        switch (newState) {
            case "planned": {
                return "Cambia Data Pianificata di Inizio"
            }
            case "started": {
                return "Conferma Inizio Corso";
            }
            case "completed": {
                return "Conferma Completamento Corso";
            }
            case "discontinued": {
                return "Conferma Rinuncia Corso";
            }
        }
    }

    function getLabel() {
        switch (newState) {
            case "planned":
            case "started": {
                return "Data di Inizio";
            }
            case "completed": {
                return "Data di Completamento";
            }
            case "discontinued": {
                return "Data di Abbandono";
            }
        }
    }

    async function doStateChange(event: FormEvent) {
        if (event.type === "submit") {
            event.preventDefault();
        }

        if (!formRef.current || !auth.userData) {
            return;
        }

        const formData = new FormData(formRef.current);
        const date = formData.get("date");
        
        if (!date) {
            return;
        }

        setProcessingRequest(true);
        let dateObject = new Date(date.toString().split("/").reverse().join("/"));
        let success = false;

        if (newState === "planned") {
            success = await api.modifyCourseAttendedStartDate(row.id, dateObject, auth.userData.access_token);
        } else {
            success = await api.modifyCourseAttendedState(row.id, newState, dateObject, auth.userData.access_token);
        }

        if (success) {
            snackNotificationContext.displaySnackbarMessage("success", "Corso aggiornato correttamente!");
            onSuccessFunction(newState);
        } else {
            snackNotificationContext.displaySnackbarMessage("error", "Impossibile completare l'operazione.");
        }

        setProcessingRequest(false);
        parentCloseFunction();
    }

    // If changing to "started" state, suggest the already present start_date as the default value.
    function getDatePickerDefaultValue() {
        if (newState === "started" && row.start_date) {
            return dayjs(row.start_date);
        }

        return dayjs();
    }

    function getDatePickerMinDate() {
        if (newState === "planned" || newState === "started" || newState === "discontinued" || !row.start_date) {
            return undefined;
        }

        return dayjs(row.start_date);
    }

    function getDatePickerMaxDate() {
        if (newState === "planned") {
            return undefined;
        }

        return dayjs();
    }

    return (
        <>
            <ModalBase open={open} parentCloseFunction={parentCloseFunction} title={getTitle()}>
                <div className="courseAttendedStateChangeModal">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                        <form ref={formRef} onSubmit={doStateChange}>
                            <DatePicker defaultValue={getDatePickerDefaultValue()}
                                minDate={getDatePickerMinDate()} maxDate={getDatePickerMaxDate()}
                                name={"date"} label={`${getLabel()}*`} sx={{ width: "100%" }} />
                            <div className="actions">
                                <Button type="submit" variant="outlined" disabled={processingRequest}>Invia</Button>
                                <Button color="error" variant="outlined" disabled={processingRequest} onClick={parentCloseFunction}>Annulla</Button>
                            </div>
                        </form>
                    </LocalizationProvider>
                </div>
            </ModalBase>
        </>
    );
}
