import { Alert, Snackbar } from "@mui/material";
import { SnackbarState } from "../../../utils/types";
import SnackbarUtils from "../../../utils/SnackbarUtils";

export default function SnackNotification({
    state,
    setStateFunction
}: {
    state: SnackbarState,
    setStateFunction: React.Dispatch<React.SetStateAction<SnackbarState>>
}) {
    const AUTO_HIDE_DURATION_MS = 6000;

    return (
        <Snackbar open={state.open} autoHideDuration={AUTO_HIDE_DURATION_MS}
            onClose={(_, reason) => SnackbarUtils.close(setStateFunction, reason)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            
            <Alert onClose={() => SnackbarUtils.close(setStateFunction)} severity={state.severity} variant="filled" sx={{ width: "100%" }}>
                {state.message}
            </Alert>
        </Snackbar>
    );
}