import "./DataTableController.scss";
import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import DataTable from "../../common/DataTable/DataTable";
import { SchemaEntry } from "../../../utils/types";
import ApiProvider from "../../../utils/ApiProvider";
import { SchemaDefinition } from "../../../utils/schemas";

export default function DataTableController({
    limitedTables,
    addItemButtonDenyList,
    editAction = false,
    deleteAction = false
}: {
    limitedTables?: Array<SchemaDefinition>,
    addItemButtonDenyList?: Array<SchemaDefinition>,
    editAction?: boolean,
    deleteAction?: boolean
}) {
    const [ schemaEntries, setSchemaEntries ] = useState<Array<SchemaEntry>>([]);
    const [ table, setTable ] = useState<SchemaEntry>({ name: "loading", display: "loading", plural: "loading" });
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        loadSelectEntries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    async function loadSelectEntries() {
        let schemaEntries = [] as SchemaEntry[];
        if (limitedTables) {
            limitedTables.forEach(definition => {
                schemaEntries.push({
                    name: definition.name,
                    plural: definition.plural,
                    display: definition.display
                }) 
            });
            setSchemaEntries(schemaEntries);
        } else {
            const entries = await import("../../../utils/schemas");
            let entryKeys = Object.keys(entries);
            entryKeys.forEach(entryKey => {
                let key = entryKey as keyof typeof entries;
                const schema = entries[key];
                schemaEntries.push({
                    name: schema.name,
                    plural: schema.plural,
                    display: schema.display
                });
            });
        }
        
        setSchemaEntries(schemaEntries);
        setTable(schemaEntries[0]);
        setLoading(false);
    }

    function handleSelection(event: SelectChangeEvent) {
        const entry = schemaEntries.find(entry => entry.name === event.target.value as string);

        if (!entry) {
            return;
        }

        setTable(entry);
    }

    function shouldHaveAddItemButton(): boolean {
        if (!addItemButtonDenyList) {
            return true;
        }

        if (addItemButtonDenyList.find(definition => definition.name === table.name)) {
            return false;
        }

        return true;
    }

    return (
        <div className="DataTableController">
            <FormControl className="formControl">
                { !loading &&
                    <>
                        <InputLabel id="table-selector-label">Tabella</InputLabel>
                        <Select className="selectTabella" label="Tabella" labelId="table-selector-label" value={table.name} onChange={handleSelection}>
                            {
                                schemaEntries.map(entry => <MenuItem key={entry.name} value={entry.name} selected={table.name === entry.name}>{entry.display}</MenuItem>)
                            }
                        </Select>
                    </>
                }
            </FormControl>
            { !loading &&
                <DataTable key={`${table.name}-table`} table={table.name} api={ApiProvider.getApi()} addItemButton={shouldHaveAddItemButton()} exportDataButton
                    editAction={editAction} deleteAction={deleteAction}></DataTable>
            }
        </div>
    );
}