import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import DataTable from "../../components/common/DataTable/DataTable";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import ApiProvider from "../../utils/ApiProvider";
import { UserSchema } from "../../utils/schemas";
import { Edit } from "@mui/icons-material";
import { useAuth } from "oidc-react";
import { useState } from "react";
import AssignManagerModal from "../../components/utenti/AssignManagerModal/AssignManagerModal";

export default function Utenti() {
    const [ assignModalOpen, setAssignModalOpen ] = useState(false);
    const [ assignModalUserId, setAssignModalUserId ] = useState<string>("");
    const [ dataTableKey, setDataTableKey ] = useState(new Date().toString());
    const auth = useAuth();

    async function assignManager(row: any) {
        if (!auth.userData) {
            return;
        }

        setAssignModalUserId(row.id);
        openAssignModal();
    }

    function openAssignModal() {
        setAssignModalOpen(true);
    }

    function closeAssignModal() {
        setAssignModalOpen(false);
    }

    function onAssignSuccess() {
        setDataTableKey(new Date().toString());
    }

    const getActions = (params: GridRowParams<any>) => {
        return [
            <GridActionsCellItem icon={<Edit />} label="Gestisci Manager" title="Gestisci Manager"
                onClick={() => assignManager(params.row)} />
        ];
    }

    return (
        <>
            <div className="content">
                <PageLabelBar title="Gestione Utenti" />
                <div className="fullWidth">
                    <DataTable key={dataTableKey} api={ApiProvider.getApi()} table={UserSchema.name} exportDataButton
                        getActions={getActions}></DataTable>
                </div>
            </div>
            <AssignManagerModal open={assignModalOpen} api={ApiProvider.getApi()} auth={auth} userId={assignModalUserId}
                onSuccessFunction={onAssignSuccess} parentOpenFunction={openAssignModal} parentCloseFunction={closeAssignModal} />
        </>
    );
}