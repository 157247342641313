import "./Dashboard.scss";
import { Stack, Typography } from "@mui/material";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import { Link } from "react-router-dom";
import ThemeUtils from "../../utils/ThemeUtils";
import InfoButton from "../../components/header/InfoButton/InfoButton";

export default function Dashboard() {
    return (
        <div className="Dashboard content">
            <PageLabelBar title="Dashboard" />
            <div className="welcome">
                <Stack direction={"column"} rowGap={"8px"}>
                    <Typography fontSize={"20px"}>Benvenuto nello <b>Skills Manager</b>! Qua potrai gestire al meglio il tuo percorso formativo aziendale.</Typography>
                    <Typography fontSize={"20px"}>&bull; Per iniziare, passa con il mouse sopra la barra a sinistra.</Typography>
                    <Typography fontSize={"20px"}>&bull; Se vuoi avere maggiori informazioni, vai al <Link to={"/tutorial"} style={{display: "inline-flex"}}><Typography fontSize={"20px"} color={(theme) => ThemeUtils.isDarkMode(theme) ? theme.palette.primary.dark : theme.palette.primary.light }>tutorial</Typography></Link>.</Typography>
                    <Typography fontSize={"20px"}>&bull; Per richiedere supporto, fai click sull'icona <InfoButton /> in alto a destra.</Typography>
                </Stack>
            </div>
        </div>
    );
}