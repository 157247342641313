import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import { useNavigate } from "react-router-dom";
import { User, useAuth } from "oidc-react";
import ApiProvider from "../../utils/ApiProvider";
import UserDataUtils from "../../utils/UserDataUtils";
import { GridActionsCellItem, GridFilterItem, GridRowParams, GridSortItem } from "@mui/x-data-grid";
import DataTable from "../../components/common/DataTable/DataTable";
import { UserSchema } from "../../utils/schemas";
import { AccessTime, Description, Lightbulb, List, ReceiptLong } from "@mui/icons-material";

export default function Team() {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.userData) {
        return (
            <div className="content">
                <PageLabelBar title="Il tuo Team" />
            </div>
        );
    }

    const api = ApiProvider.getApi();
    
    const getRowCount = async (tableName: string, token: string): Promise<number> => {
        return api.countSubordinates(UserDataUtils.getUserId(auth.userData as User), token);
    }

    const getData = async (tableName: string, token: string, pageSize: number, pageOffset: number,
        filters: GridFilterItem[], sort: GridSortItem[]): Promise<Array<{[key: string]: any}>> => {

        return api.getSubordinates(UserDataUtils.getUserId(auth.userData as User), token,
            pageSize, pageOffset, filters, sort);
    }

    const getActions = (params: GridRowParams<any>) => {
        return [
            <GridActionsCellItem icon={<List />} label="Corsi" title="Corsi"
                onClick={() => navigate("/corsi", { state: getNavigationState(params) })} />,
            <GridActionsCellItem icon={<AccessTime />} label="Proposte" title="Proposte"
                onClick={() => navigate("/proposte", { state: getNavigationState(params) })} />,
            <GridActionsCellItem icon={<Description />} label="Certificazioni" title="Certificazioni"
                onClick={() => navigate("/certificazioni", { state: getNavigationState(params) })} />,
            <GridActionsCellItem icon={<Lightbulb />} label="Skills" title="Skills"
                onClick={() => navigate("/skills", { state: getNavigationState(params) })} />,
            <GridActionsCellItem icon={<ReceiptLong />} label="Resoconto" title="Resoconto"
                onClick={() => navigate("/resoconto", { state: getNavigationState(params) })} />
        ];
    }

    function getNavigationState(params: GridRowParams<any>) {
        return { userId: params.row.id, name: `${params.row.name} ${params.row.surname}` }
    }

    return (
        <div className="content">
            <PageLabelBar title="Il tuo Team" />
            <div className="fullWidth">
                <DataTable api={api} table={UserSchema.name} overrideGetRowCount={getRowCount}
                    overrideGetData={getData} getActions={getActions}></DataTable>
            </div>
        </div>
    );
}