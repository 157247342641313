import { Add, Cancel, Edit, Flag, MoreVert, PlayArrow } from "@mui/icons-material";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";

export default function CorsiUseCases() {
    return (
        <>
            <Divider />
            <Stack direction={"column"} gap={"12px"} sx={{ marginTop: "12px" }}>
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Aggiungere un Corso iniziato o svolto in precedenza</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Fare click sul bottone <Button variant="contained" startIcon={<Add />}>Aggiungi</Button> collocato sopra alla tabella.</Typography>
                        <Typography fontSize={"18px"}>&bull; Aprire il dropdown del campo <b>Corso</b>, ed iniziare a scrivere il nome del corso voluto.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Se il corso compare nella lista, sceglierlo e procedere a riempire il resto dei campi.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Altrimenti, il corso non &egrave; presente nel database e pu&ograve; eventualmente essere aggiunto da un Data Manager.</Typography>
                    </Stack>
                </div>
                <Divider />
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Aggiornare lo stato di un Corso</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Aprire il Menu facendo click sull'icona <IconButton><MoreVert /></IconButton> in fondo alla riga del corso che si vuole modificare.</Typography>
                        <Typography fontSize={"18px"}>&bull; Scegliere l'azione che si vuole compiere. Le opzioni sono:</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><Edit /></IconButton> Modificare la data d'inizio di un Corso Pianificato;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><PlayArrow /></IconButton> Iniziare un Corso Pianificato;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><Flag /></IconButton> Completare un Corso Iniziato;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><Cancel /></IconButton> Abbandonare un Corso Pianificato o Iniziato.</Typography>
                    </Stack>
                </div>
                <Divider />
                <div>
                    <Typography fontSize={"20px"} fontWeight={"bold"}>Passare velocemente alla pagina delle Proposte</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Fare click sul bottone <Button variant="contained" startIcon={<Add />}>Proponi Nuovo</Button> collocato sopra alla tabella.</Typography>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}