import { User } from "oidc-react";

const UserDataUtils = {
    getUserId(userData: User): string {
        return String(userData.profile["custom:user_id"]);
    },

    getEmail(userData: User): string {
        return String(userData.profile.email);
    },

    getName(userData: User): string {
        return `${userData.profile.given_name} ${userData.profile.family_name}`;
    },

    userHasRole(userData: User, role: string): boolean {
        return (userData.profile["cognito:groups"] as Array<string>).includes(role);
    }
}

export default UserDataUtils;
