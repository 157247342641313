import { Info } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import TextDisplayModal from "../../common/TextDisplayModal/TextDisplayModal";
import { useState } from "react";

export default function InfoButton() {
    const [ textModalOpen, setTextModalOpen ] = useState(false);
    
    return (
        <>
            <IconButton onClick={() => setTextModalOpen(true)}>
                <Info />
            </IconButton>
            <TextDisplayModal open={textModalOpen} parentCloseFunction={() => setTextModalOpen(false)} parentOpenFunction={() => setTextModalOpen(true)}
                title="Richiesta di Supporto" text={`Per eventuali domande, o per ricevere supporto, invia una mail a\nskillmanager.support@tai.it`}>
            </TextDisplayModal>
        </>
    );
}