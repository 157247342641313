import { AccessTime, Add, Check, Close, Description, Lightbulb, List, MoreVert, ReceiptLong } from "@mui/icons-material";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";

export default function TeamUseCases() {
    return (
        <>
            <Divider />
            <Stack direction={"column"} gap={"12px"} sx={{ marginTop: "12px" }}>
                <div>
                <Typography fontSize={"20px"} fontWeight={"bold"}>Accedere alle informazioni dei membri del Team</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Aprire il Menu facendo click sull'icona <IconButton><MoreVert /></IconButton> in fondo alla riga del membro desiderato.</Typography>
                        <Typography fontSize={"18px"}>&bull; Scegliere l'azione che si vuole compiere. Le opzioni sono:</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><List /></IconButton> Visualizzare i Corsi seguiti dal membro;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><AccessTime /></IconButton> Visualizzare le Proposte formative del membro;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><Description /></IconButton> Visualizzare le Certificazioni acquisite dal membro;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><Lightbulb /></IconButton> Visualizzare le Skill del membro;</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp; <IconButton><ReceiptLong /></IconButton> Visualizzare il Resoconto del percorso formativo del membro.</Typography>
                    </Stack>
                </div>
                <Divider />
                <div>
                <Typography fontSize={"20px"} fontWeight={"bold"}>Approvare o Rifiutare le proposte di un membro del Team</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Aprire la pagina Proposte del membro desiderato (vedi <b>Accedere alle informazioni dei membri del Team</b>).</Typography>
                        <Typography fontSize={"18px"}>&bull; Individuare la proposta in stato "In Approvazione" che si desidera approvare o rifiutare, ed aprire il suo Menu <IconButton><MoreVert /></IconButton> corrispondente.</Typography>
                        <Typography fontSize={"18px"}>&bull; Selezionare <IconButton><Check /></IconButton>Approva, oppure <IconButton><Close /></IconButton>Rifiuta, e procedere.</Typography>
                        <Typography fontSize={"18px"}>&bull; Approvando una proposta, succedono le seguenti cose:</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;1. Se nella proposta sono inseriti i dati di un Nuovo Corso, esso viene aggiunto al database.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&nbsp;&nbsp;&rarr; Prima di approvare una proposta, controlla che i dati siano <b>giusti</b> e <b>scritti in modo corretto</b>.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;2. In ogni caso, viene inserito un nuovo Corso tra quelli seguiti dal membro a cui appartiene la proposta.</Typography>
                    </Stack>
                </div>
                <Divider />
                <div>
                <Typography fontSize={"20px"} fontWeight={"bold"}>Aggiungere una nuova Proposta formativa ad un membro del Team</Typography>
                    <Stack direction={"column"} gap={"8px"} sx={{ marginTop: "8px" }}>
                        <Typography fontSize={"18px"}>&bull; Aprire la pagina Proposte del membro desiderato (vedi <b>Accedere alle informazioni dei membri del Team</b>).</Typography>
                        <Typography fontSize={"18px"}>&bull; Fare click sul bottone <Button variant="contained" startIcon={<Add />}>Aggiungi</Button> collocato sopra alla tabella.</Typography>
                        <Typography fontSize={"18px"}>&bull; Aprire il dropdown del campo <b>Corso</b>, ed iniziare a scrivere il nome del corso voluto.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Se il corso compare nella lista, sceglierlo e procedere a riempire il resto dei campi.</Typography>
                        <Typography fontSize={"18px"}>&nbsp;&nbsp;&rarr; Altrimenti, il corso non &egrave; presente nel database. Selezionare "Nuovo Corso", inserire i dati del corso voluto, e riempire il resto dei campi.</Typography>
                        <Typography fontSize={"18px"}>&bull; La proposta verrà creata già in stato "In Approvazione", e potrai subito approvarla.</Typography>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}