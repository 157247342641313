import ApiAdapter from "../api/ApiAdapter";
import localApi from "../api/LocalApi";
import remoteApi from "../api/RemoteApi";

// Singular place from which to switch which Api the application will use.
const local = false;

const ApiProvider = {
    getApi(): ApiAdapter {
        if (local) {
            return localApi;
        }
        return remoteApi;
    }
}

export default ApiProvider;
