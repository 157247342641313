import { useTheme } from "@mui/material";
import "./Logo.scss";
import { Link } from "react-router-dom";

export default function Logo({
    width,
    height
}: {
    width: number,
    height: number
}) {
    const theme = useTheme();

    let white = theme.palette.mode === "light" ? "black" : "white";

    return (
        <Link to="/">
            <div className="Logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.42 206.85" style={{ width: width, height: height }}>
                    <g>
                        <path className="none" d="M195.84,100.63A92.57,92.57,0,1,0,92.63,195.27"></path>
                        <path className="darkRed" d="M103.31,0A103.31,103.31,0,0,0,92.63,206.07v-10.8a92.58,92.58,0,1,1,103.21-94.64h10.74A103.31,103.31,0,0,0,103.31,0Z"></path>
                        <path className={white} d="M109.42,183.85V136.7H92.68v-9.61h44.23v9.61H120.09v47.16Z"></path>
                        <path className={white} d="M161.84,146.46,144,183.85H132.18l29.74-59.32,29.74,59.32H179.71Z"></path>
                        <path className={white} d="M196.53,183.85V127H207.2v56.84Z"></path>
                        <path className="red" d="M161.95,167.15a8.36,8.36,0,1,0,8.72,8.43A8.48,8.48,0,0,0,161.95,167.15Z"></path>
                        <path className={white} d="M110.79,202.87a5.38,5.38,0,0,0,3.51,1.48c1.39,0,2.12-.65,2.12-1.64,0-2.88-6-2.43-6-6.59,0-2.43,2.18-3.72,4.45-3.72a5.78,5.78,0,0,1,3.87,1.58l-1.24,1.91a3.92,3.92,0,0,0-2.61-1.21c-.92,0-1.8.4-1.8,1.33,0,2.39,6.1,1.85,6.1,6.66,0,2.11-1.85,4-5,4a7.24,7.24,0,0,1-4.64-1.94Z"></path>
                        <path className={white} d="M128.07,206.65a7.13,7.13,0,1,1,0-14.24,7.22,7.22,0,0,1,7.43,7.18A7.33,7.33,0,0,1,128.07,206.65Zm0-11.93a4.75,4.75,0,0,0-4.77,4.75,4.89,4.89,0,0,0,4.64,4.9,4.82,4.82,0,0,0,.13-9.65Z"></path>
                        <path className={white} d="M137.66,206.33V192.72h2.56V204h5.69v2.32Z"></path>
                        <path className={white} d="M156,192.72h2.56v12a7.56,7.56,0,0,1-5.65,2c-3.6,0-5.62-1.76-5.62-5.34v-8.6h2.56v7.13c0,3.42.7,4.53,3.47,4.53a4.92,4.92,0,0,0,2.68-.72Z"></path>
                        <path className={white} d="M164.22,206.34V195h-4v-2.3h10.6V195h-4v11.3Z"></path>
                        <path className={white} d="M172.53,206.34V192.72h2.56v13.62Z"></path>
                        <path className={white} d="M184.74,206.65a7.13,7.13,0,1,1,0-14.24,7.22,7.22,0,0,1,7.43,7.18A7.33,7.33,0,0,1,184.74,206.65Zm0-11.93a4.75,4.75,0,0,0-4.77,4.75,4.89,4.89,0,0,0,4.64,4.9,4.82,4.82,0,0,0,.13-9.65Z"></path>
                        <path className={white} d="M196.83,198l0,8.31h-2.56l0-14,10,9.05,0-8.67h2.56l0,14.13Z"></path>
                        <path className={white} d="M210.07,202.87a5.38,5.38,0,0,0,3.51,1.48c1.39,0,2.12-.65,2.12-1.64,0-2.88-6-2.43-6-6.59,0-2.43,2.18-3.72,4.45-3.72A5.78,5.78,0,0,1,218,194l-1.24,1.91a3.92,3.92,0,0,0-2.61-1.21c-.92,0-1.8.4-1.8,1.33,0,2.39,6.1,1.85,6.1,6.66,0,2.11-1.85,4-5,4a7.24,7.24,0,0,1-4.64-1.94Z"></path>
                    </g>
                </svg>
            </div>
        </Link>
    );
}