import { Close } from "@mui/icons-material";
import { Divider, IconButton, Modal, Paper, Stack, Typography } from "@mui/material";

export default function ModalBase({
    open,
    title,
    parentCloseFunction,
    children
}: {
    open: boolean,
    title: string,
    parentCloseFunction: () => void,
    children: JSX.Element | JSX.Element[]
}) {
    return (
        <Modal open={open} onClose={() => parentCloseFunction()}>
            <Paper sx={modalBoxStyle}>
                <IconButton onClick={() => parentCloseFunction()}
                    sx={{ position: "absolute", top: "4px", right: "4px" }}>
                        <Close />
                </IconButton>
                <Stack>
                    <Typography sx={{ fontSize: "22px", fontWeight: "bold", textAlign: "center", marginBottom: "8px" }}>
                        {title}
                    </Typography>
                    <Divider sx={{ marginBottom: "16px" }} />
                    {children}
                </Stack>
            </Paper>
        </Modal>
    );
}

const modalBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    padding: "32px",
    border: "1px solid"
}