import { GridFilterItem, GridSortItem } from "@mui/x-data-grid";
import FakeUtils from "../utils/fakeUtils";
import ApiAdapter from "./ApiAdapter";

class LocalApi implements ApiAdapter {
    async getAllData(tableName: string, token: string): Promise<Array<{[key: string]: any}>> {
        console.log("Called local `getAllData` with tableName:", tableName);
        await FakeUtils.sleep(1000);
        return new Promise(async (resolve, reject) => {
            try {
                const data = (await import(`../fakedata/${tableName}.json`)).default as Array<{[key: string]: any}>;
                resolve(data);
            } catch (_) {
                reject([{ error: "The specified table name was not found." }]);
            }
        });
    }

    async getAllDataByUserId(userId: string, tableName: string, token: string): Promise<Array<{[key: string]: any}>> {
        throw new Error("Unimplemented Operation");
    }

    async getRowCount(tableName: string, token: string): Promise<number> {
        console.log("Called local `getRowCount` with tableName:", tableName);
        await FakeUtils.sleep(1000);
        return new Promise(async (resolve, reject) => {
            try {
                const count = ((await import(`../fakedata/${tableName}.json`)).default as Array<{[key: string]: any}>).length;
                resolve(count);
            } catch (_) {
                reject([{ error: "The specified table name was not found." }]);
            }
        })
    }

    async getRowCountByUserId(userId: string, tableName: string, token: string): Promise<number> {
        console.log("Called local `getRowCountByUserId with userId:", userId, ", tableName:", tableName);
        await FakeUtils.sleep(1000);
        return new Promise(async (resolve, reject) => {
            let dataCopy;
            try {
                const data = (await import(`../fakedata/${tableName}.json`)).default as Array<{[key: string]: any}>;
                dataCopy = structuredClone(data) as Array<{[key: string]: any}>;
            } catch (_) {
                reject([{ error: "The specified table name was not found." }]);
                return;
            }

            // Filter by userId (if the data has a userId field)
            dataCopy = dataCopy.filter(entry => !entry["userId"] || entry["userId"] === userId);

            resolve(dataCopy.length);
        })
    }

    async getData(tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {

        console.log("Called local `getData` with tableName:", tableName, ", pageSize:", pageSize, ", pageOffset:", pageOffset,
            ", filters:", filters, ", sort:", sort);
        await FakeUtils.sleep(1000);
        return new Promise(async (resolve, reject) => {
            let dataCopy: Array<{[key: string]: any}>;

            try {
                const data = (await import(`../fakedata/${tableName}.json`)).default as Array<{[key: string]: any}>;
                dataCopy = structuredClone(data) as Array<{[key: string]: any}>;
            } catch (_) {
                reject([{ error: "The specified table name was not found." }]);
                return;
            }
    
            // Filtering
            filters.forEach(filter => {
                if (filter.operator === "contains" && filter.value) {
                    dataCopy = dataCopy.filter(entry => entry[filter.field] && entry[filter.field].includes(filter.value));
                }
            });
    
            // Sorting
            sort.forEach(entry => {
                if (entry.sort === "asc") {
                    dataCopy = dataCopy.sort((a, b) => a[entry.field] > b[entry.field] ? 1 : -1);
                } else if (entry.sort === "desc") {
                    dataCopy = dataCopy.sort((a, b) => a[entry.field] < b[entry.field] ? 1 : -1);
                }
            });
    
            // Pagination
            if (dataCopy.length > pageSize) {
                let startIndex = pageSize * pageOffset;
                dataCopy = dataCopy.splice(startIndex, pageSize);
            }
    
            resolve(dataCopy);
        });
    }

    async getDataByUserId(userId: string, tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {

        console.log("Called local `getData` with userId:", userId, ", tableName:", tableName, ", pageSize:", pageSize,
            ", pageOffset:", pageOffset, ", filters:", filters, ", sort:", sort);
        await FakeUtils.sleep(1000);
        return new Promise(async (resolve, reject) => {
            let dataCopy: Array<{[key: string]: any}>;

            try {
                const data = (await import(`../fakedata/${tableName}.json`)).default as Array<{[key: string]: any}>;
                dataCopy = structuredClone(data) as Array<{[key: string]: any}>;
            } catch (_) {
                reject([{ error: "The specified table name was not found." }]);
                return;
            }

            // Filter by userId (if the data has a userId field)
            dataCopy = dataCopy.filter(entry => !entry["userId"] || entry["userId"] === userId);

            // Filtering
            filters.forEach(filter => {
                if (filter.operator === "contains" && filter.value) {
                    dataCopy = dataCopy.filter(entry => entry[filter.field] && entry[filter.field].includes(filter.value));
                }
            });
    
            // Sorting
            sort.forEach(entry => {
                if (entry.sort === "asc") {
                    dataCopy = dataCopy.sort((a, b) => a[entry.field] > b[entry.field] ? 1 : -1);
                } else if (entry.sort === "desc") {
                    dataCopy = dataCopy.sort((a, b) => a[entry.field] < b[entry.field] ? 1 : -1);
                }
            });
    
            // Pagination
            if (dataCopy.length > pageSize) {
                let startIndex = pageSize * pageOffset;
                dataCopy = dataCopy.splice(startIndex, pageSize);
            }
    
            resolve(dataCopy);
        });
    }

    async getNonTemporaryCourses(tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {
            
        throw new Error("Unimplemented Operation");
    }

    async addDataRow(tableName: string, token: string, data: Object): Promise<boolean> {
        await FakeUtils.sleep(1000);

        return new Promise(async (resolve, reject) => {
            console.log("Added row:", JSON.stringify(data));

            resolve(true);
        })
    }

    async modifyDataRow(tableName: string, token: string, rowId: string, data: Object): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async deleteDataRow(tableName: string, token: string, rowId: string): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async patchDataRow(tableName: string, token: string, rowId: string, data: Object): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async countSubordinates(managerId: string, token: string): Promise<number> {
        throw new Error("Unimplemented Operation");
    }

    async getSubordinates(managerId: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {

        throw new Error("Unimplemented Operation");
    }

    async assignManager(managerId: string, userId: string, token: string): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async requestProposalApproval(proposalId: string, token: string): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async approveProposal(proposalId: string, token: string): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async rejectProposal(proposalId: string, token: string, manager_note?: string): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }

    async getNotifications(userId: string, token: string): Promise<Array<{[key: string]: any}>> {
        throw new Error("Unimplemented Operation");
    }

    async modifyCourseAttendedState(courseAttendedId: string, newState: "started" | "completed" | "discontinued",
        relevantDate: Date, token: string): Promise<boolean> {
            
        throw new Error("Unimplemented Operation");
    }

    async modifyCourseAttendedStartDate(courseAttendedId: string, startDate: Date, token: string): Promise<boolean> {
        throw new Error("Unimplemented Operation");
    }
}

const localApi = new LocalApi();
export default localApi;
