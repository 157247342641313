import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import DataTableController from "../../components/database/DataTableController/DataTableController";

export default function Database() {
    return (
        <div className="content">
            <PageLabelBar title="Gestione Database" />
            <DataTableController editAction deleteAction />
        </div>
    );
}