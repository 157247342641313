import "./AssignManagerModal.scss";
import { AuthContextProps } from "oidc-react";
import ApiAdapter from "../../../api/ApiAdapter";
import ModalBase from "../../common/ModalBase/ModalBase";
import { FormEvent, SyntheticEvent, useContext, useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { SelectOption } from "../../../utils/types";
import { UserSchema } from "../../../utils/schemas";
import { SnackNotificationContext } from "../../../app/App";

export default function AssignManagerModal({
    open,
    parentOpenFunction,
    parentCloseFunction,
    auth,
    api,
    userId,
    onSuccessFunction
}: {
    open: boolean,
    parentOpenFunction: () => void,
    parentCloseFunction: () => void,
    auth: AuthContextProps,
    api: ApiAdapter,
    userId: string,
    onSuccessFunction: () => void
}) {
    const [ managerOptions, setManagerOptions ] = useState<SelectOption[]>([]);
    const [ selectedOption, setSelectedOption ] = useState<SelectOption | undefined>(undefined);
    const [ error, setError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const snackNotificationContext = useContext(SnackNotificationContext);

    const searchDebounceTimeMs = 500;
    let debounceTimeoutId: number = -1;

    function handleInputChangeWithDebounce(event: SyntheticEvent<Element, Event>, searchTerm: string) {
        if (debounceTimeoutId === -1) {
            debounceTimeoutId = setTimeout(() => handleInputChange(event, searchTerm), searchDebounceTimeMs) as unknown as number;
        } else {
            clearTimeout(debounceTimeoutId);
            debounceTimeoutId = setTimeout(() => handleInputChange(event, searchTerm), searchDebounceTimeMs) as unknown as number;
        }
    }

    function closeModal() {
        clearOptions();
        parentCloseFunction();
        setSelectedOption(undefined);
    }
    
    function clearOptions() {
        setManagerOptions([]);
        setError(false);
    }

    async function handleInputChange(event: SyntheticEvent<Element, Event>, searchTerm: string) {
        clearOptions();

        if (!auth.userData || searchTerm === "") {
            return;
        }

        setLoading(true);
        // Search by surname first, name second, then merge the found options.
        let foundRowsBySurname = await api.getData(UserSchema.name, auth.userData.access_token, 10, 0,
            [{ field: "surname", operator: "contains_case_insensitive", value: searchTerm }], []);
        let foundRowsByName = await api.getData(UserSchema.name, auth.userData.access_token, 10, 0,
            [{ field: "name", operator: "contains_case_insensitive", value: searchTerm }], []);
        const finalFoundRows = structuredClone(foundRowsBySurname) as {[key: string]: any}[];
        foundRowsByName.forEach(row => {
            if (!finalFoundRows.find(comparingRow => comparingRow.name === row.name || comparingRow.surname === row.surname)) {
                finalFoundRows.push(row);
            }
        });
            
        const result: SelectOption[] = finalFoundRows.map(row => {
            return { name: `${row.name} ${row.surname}`, value: row.id };
        });
        setManagerOptions(result);
        setLoading(false);
    }

    function handleSelect(event: SyntheticEvent<Element, Event>, value: string | null, fieldName: string) {
        if (value === null || value === "") {
            return;
        }

        setSelectedOption(managerOptions.find(option => option.name === value));
    }
    
    async function assignManager(event: FormEvent) {
        if (event.type === "submit") {
            event.preventDefault();
        }

        if (!auth.userData) {
            return;
        }

        if (selectedOption === undefined) {
            setError(true);
            return;
        }
        setError(false);

        let managerId = selectedOption.value;
        let success = await api.assignManager(managerId, userId, auth.userData.access_token);

        if (success) {
            snackNotificationContext.displaySnackbarMessage("success", "Manager assegnato!");
            onSuccessFunction();
        } else {
            snackNotificationContext.displaySnackbarMessage("error", "Impossibile completare l'operazione.");
        }

        closeModal();
    }

    return (
        <>
            <ModalBase open={open} parentCloseFunction={closeModal} title="Assegna Nuovo Manager">
                <div className="assignManagerModal">
                    <form onSubmit={assignManager}>
                        <Autocomplete
                            filterOptions={x => x}
                            options={managerOptions.map(option => option.name)}
                            renderInput={params => {
                                    return <div className="fieldContainer">
                                            <TextField placeholder="Ricerca per Nome" {...params}
                                                label="Nome Manager"
                                                name="manager_id" sx={{ width: "100%" }}
                                                error={error} />
                                        </div>
                                }
                            }
                            onInputChange={handleInputChangeWithDebounce}
                            onClose={clearOptions}
                            onChange={handleSelect}
                            loading={loading} />
                        <div className="actions">
                            <Button type="submit" variant="outlined">Invia</Button>
                            <Button color="error" variant="outlined" onClick={closeModal}>Annulla</Button>
                        </div>
                    </form>
                </div>
            </ModalBase>
        </>
    );
}