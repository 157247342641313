import { GridFilterItem, GridSortItem } from "@mui/x-data-grid";
import ApiAdapter from "./ApiAdapter";
import Axios, { RawAxiosRequestHeaders } from "axios";
import { cloudEnv } from "../cloud-env";
import SchemaUtils from "../utils/SchemaUtils";

const filtersApiMap = {
    // String
    "contains": "contains",
    "contains_case_insensitive": "contains_case_insensitive",
    "equals": "eq",
    "startsWith": "starts_with",
    "endsWith": "ends_with",
    // Date-Time
    "before": "gt",
    "onOrBefore": "gte",
    "after": "lt",
    "onOrAfter": "lte",
    // Boolean
    "is": "eq",
    // Number
    "=": "eq",
    "!=": "neq",
    ">": "gt",
    ">=": "gte",
    "<": "lt",
    "<=": "lte",
    // Common
    "isEmpty": "is_null",
    "isNotEmpty": "is_not_null"
}

const filtersWithoutValue = [ filtersApiMap.isEmpty, filtersApiMap.isNotEmpty ];

class RemoteApi implements ApiAdapter {
    getAuthorizationHeader(token: string): Partial<RawAxiosRequestHeaders> {
        return { Authorization: `Bearer ${token}` };
    }

    getFiltersArray(filters: Array<GridFilterItem>): Array<string> {
        let result: Array<string> = [];
        filters.forEach(filter => {
            if (!Object.keys(filtersApiMap).includes(filter.operator)) {
                return;
            }

            let apiFilterName = filtersApiMap[filter.operator as keyof typeof filtersApiMap];
            if (filtersWithoutValue.includes(apiFilterName)) {
                result.push(`${filter.field}:${apiFilterName}`);
                return;
            }

            if (filter.value) {
                result.push(`${filter.field}:${apiFilterName}:${filter.value}`);
            }
        });
        return result;
    }

    getSortArray(sort: Array<GridSortItem>): Array<string> {
        let result: Array<string> = [];
        sort.forEach(sortItem => {
            if (sortItem.sort) {
                result.push(`${sortItem.field}:${sortItem.sort}`);
            }
        });
        return result;
    }

    // Interface Methods

    // /<resource_name>
    async getAllData(tableName: string, token: string): Promise<Array<{[key: string]: any}>> {
        return new Promise(async (resolve, reject) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/${tableApiName}`);

            try {
                const data = await Axios.get(url.toString(), {headers: this.getAuthorizationHeader(token)});
                resolve(data.data);
            } catch (e: any) {
                reject(`Could not complete getAllData request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // /users/{user_id}/<resource_name>
    async getAllDataByUserId(userId: string, tableName: string, token: string): Promise<Array<{[key: string]: any}>> {
        return new Promise(async (resolve, reject) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/users/${userId}/${tableApiName}`);

            try {
                const data = await Axios.get(url.toString(), {headers: this.getAuthorizationHeader(token)});
                resolve(data.data);
            } catch (e: any) {
                reject(`Could not complete getAllDataByUserId request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // /count/<resource_name>
    async getRowCount(tableName: string, token: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/count/${tableApiName}`);

            try {
                const data = await Axios.get(url.toString(), {headers: this.getAuthorizationHeader(token)});
                resolve(Number(data.data.count));
            } catch (e: any) {
                reject(`Could not complete getRowCount request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // /count/<resource_name>?filter=user_id:eq:<user_id>
    async getRowCountByUserId(userId: string, tableName: string, token: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/count/${tableApiName}`);
            url.searchParams.append("filter", `user_id:eq:${userId}`);

            try {
                const data = await Axios.get(url.toString(), {headers: this.getAuthorizationHeader(token)});
                resolve(Number(data.data.count));
            } catch (e: any) {
                reject(`Could not complete getRowCountByUserId request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // /<resource_name>
    async getData(tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {
        
        return new Promise(async (resolve, reject) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/${tableApiName}`);

            url.searchParams.append("limit", String(pageSize));
            url.searchParams.append("offset", String(pageOffset * pageSize));
            this.getFiltersArray(filters).forEach(filter => url.searchParams.append("filter", filter));
            this.getSortArray(sort).forEach(sortItem => url.searchParams.append("sort", sortItem));

            try {
                const data = await Axios.get(url.toString(), {headers: this.getAuthorizationHeader(token)});
                resolve(data.data);
            } catch (e: any) {
                reject(`Could not complete getData request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // /users/{user_id}/<resource_name>
    async getDataByUserId(userId: string, tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {

        return new Promise(async (resolve, reject) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/users/${userId}/${tableApiName}`);
    
            url.searchParams.append("limit", String(pageSize));
            url.searchParams.append("offset", String(pageOffset * pageSize));
            this.getFiltersArray(filters).forEach(filter => url.searchParams.append("filter", filter));
            this.getSortArray(sort).forEach(sortItem => url.searchParams.append("sort", sortItem)); 
    
            try {
                const data = await Axios.get(url.toString(), { headers: this.getAuthorizationHeader(token) });
                resolve(data.data);
            } catch (e: any) {
                reject(`Could not complete getDataByUserId request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // GET /courses
    async getNonTemporaryCourses(tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {
            
        return new Promise(async (resolve, reject) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/courses`);

            url.searchParams.append("limit", String(pageSize));
            url.searchParams.append("offset", String(pageOffset * pageSize));
            url.searchParams.append("filter", "temporary:eq:false");
            this.getFiltersArray(filters).forEach(filter => url.searchParams.append("filter", filter));
            this.getSortArray(sort).forEach(sortItem => url.searchParams.append("sort", sortItem));

            try {
                const data = await Axios.get(url.toString(), {headers: this.getAuthorizationHeader(token)});
                resolve(data.data);
            } catch (e: any) {
                reject(`Could not complete getNonTemporaryCourses request at URL ${url.toString()}\nStatus code: ${e.code}\nResponse message: ${e.response.data.message}\n`);
            }
        });
    }

    // POST /<resource_name>
    async addDataRow(tableName: string, token: string, data: Object): Promise<boolean> {
        return new Promise(async (resolve) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/${tableApiName}`)
            
            try {
                await Axios.post(url.toString(), data, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // POST /<resource_name>/{resource_id}/modify
    async modifyDataRow(tableName: string, token: string, rowId: string, data: Object): Promise<boolean> {
        return new Promise(async (resolve) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/${tableApiName}/${rowId}/modify`);

            try {
                await Axios.post(url.toString(), data, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // DELETE /<resource_name>/{resource_id}
    async deleteDataRow(tableName: string, token: string, rowId: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/${tableApiName}/${rowId}`);

            try {
                await Axios.delete(url.toString(), { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // PATCH /<resource_name>/{resource_id}
    async patchDataRow(tableName: string, token: string, rowId: string, data: Object): Promise<boolean> {
        return new Promise(async (resolve) => {
            let tableApiName = await SchemaUtils.getTableApiName(tableName);
            let url = new URL(`${cloudEnv.apiEndpoint}/${tableApiName}/${rowId}`);

            try {
                await Axios.patch(url.toString(), data, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // /users/{manager_id}/subordinates
    async countSubordinates(managerId: string, token: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/users/${managerId}/subordinates`);

            try {
                const data = await Axios.get(url.toString(), { headers: this.getAuthorizationHeader(token) });
                resolve(data.data.length);
            } catch (_) {
                reject(`Something went wrong trying to get the count of subordinates for user ${managerId}`);
            }
        });
    }

    async getSubordinates(managerId: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>): Promise<Array<{[key: string]: any}>> {

        return new Promise(async (resolve, reject) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/users/${managerId}/subordinates`);
    
            url.searchParams.append("limit", String(pageSize));
            url.searchParams.append("offset", String(pageOffset * pageSize));
            this.getFiltersArray(filters).forEach(filter => url.searchParams.append("filter", filter));
            this.getSortArray(sort).forEach(sortItem => url.searchParams.append("sort", sortItem)); 
    
            try {
                const data = await Axios.get(url.toString(), { headers: this.getAuthorizationHeader(token) });
                resolve(data.data);
            } catch (_) {
                reject(`Something went wrong trying to get the subordinates for user ${managerId}`);
            }
        });
    }

    // POST /users/{user_id}/assignManager with non-null manager_id in body
    async assignManager(managerId: string, userId: string, token: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/users/${userId}/assignManager`);
            let payload = { manager_id: managerId };

            try {
                await Axios.post(url.toString(), payload, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // POST /proposals/{proposal_id}/requestApproval
    async requestProposalApproval(proposalId: string, token: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/proposals/${proposalId}/requestApproval`);

            try {
                await Axios.post(url.toString(), {}, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // POST /proposals/{proposal_id}/approve
    async approveProposal(proposalId: string, token: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/proposals/${proposalId}/approve`);

            try {
                await Axios.post(url.toString(), {}, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // POST /proposals/{proposal_id}/reject
    async rejectProposal(proposalId: string, token: string, manager_note?: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/proposals/${proposalId}/reject`);
            let payload = {} as {[key: string]: string};

            if (manager_note && manager_note !== "") {
                payload.manager_note = manager_note;
            }

            try {
                await Axios.post(url.toString(), payload, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // GET /users/{user_id}/notifications
    async getNotifications(userId: string, token: string): Promise<Array<{[key: string]: any}>> {
        return new Promise(async (resolve, reject) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/users/${userId}/notifications`);

            try {
                const data = await Axios.get(url.toString(), { headers: this.getAuthorizationHeader(token) });
                resolve(data.data);
                return;
            } catch (e) {
                console.error(e);
                reject("Could not get notifications");
            }
        });
    }

    // POST /coursesAttended/{course_attended_id}/modify
    async modifyCourseAttendedState(courseAttendedId: string, newState: "started" | "completed" | "discontinued",
        relevantDate: Date, token: string): Promise<boolean> {

        return new Promise(async (resolve) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/coursesAttended/${courseAttendedId}/modify`);
            let payload: {[key: string]: string} = { state: newState };
            switch (newState) {
                case "started": {
                    payload["start_date"] = relevantDate.toISOString();
                    break;
                }
                case "completed": {
                    payload["completion_date"] = relevantDate.toISOString();
                    break;
                }
                case "discontinued": {
                    payload["discontinuance_date"] = relevantDate.toISOString();
                    break;
                }
            }

            try {
                await Axios.post(url.toString(), payload, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }

    // POST /coursesAttended/{course_attended_id}/modify
    async modifyCourseAttendedStartDate(courseAttendedId: string, startDate: Date, token: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            let url = new URL(`${cloudEnv.apiEndpoint}/coursesAttended/${courseAttendedId}/modify`);
            let payload: {[key: string]: string} = { start_date: startDate.toISOString() };

            try {
                await Axios.post(url.toString(), payload, { headers: this.getAuthorizationHeader(token) });
                resolve(true);
                return;
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }
}

const remoteApi = new RemoteApi();
export default remoteApi;
